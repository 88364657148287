import {IconButton, IconButtonProps} from '@material-ui/core'
import Logout from '@material-ui/icons/Settings'
import React from 'react'

interface LogoutIconProps {
  fill: string
  className: string
  otherProps: IconButtonProps
  children: React.ReactNode
}

export const LogoutIcon: React.FC<LogoutIconProps> = ({
  className: classNameProp,
  fill,
  children,
  ...otherProps
}) => (
  <IconButton className={classNameProp} {...otherProps}>
    <Logout className={fill} />
  </IconButton>
)
