import {Localization} from '@hconnect/uikit'

const props = {
  backend: {
    loadFile: '{{lng}}/translations',
    loader: (filename) => {
      if (process.env.NODE_ENV === 'development') {
        console.info(`Load Translation for truckmonitor [${filename}]`)
      }
      return import(`./${filename}.json`).catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(`Could not load Translation for truckmonitor [${filename}]`)
        }
        return {err}
      })
    }
  }
}

export default () => Localization(props)
