import {connectRouter} from 'connected-react-router'
import {combineReducers} from 'redux'

import mapReducer from './modules/Map.reducer'

const messages = (
  state = {
    error: null
  },
  action
) => {
  switch (action.type) {
    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        error: action.data
      }

    case 'CLEAR_ERROR_MESSAGE':
      return {
        ...state,
        error: null
      }

    default:
      return state
  }
}

export default (history) =>
  combineReducers({
    messages,
    map: mapReducer,
    router: connectRouter(history)
  })
