import React from 'react'
import {Helmet} from 'react-helmet-async'

import appleIcon120 from './apple-touch-icon-120x120.png'
import appleIcon152 from './apple-touch-icon-152x152.png'
import appleIcon180 from './apple-touch-icon-180x180.png'
import appleIcon60 from './apple-touch-icon-60x60.png'
import appleIcon76 from './apple-touch-icon-76x76.png'
import browserconfig from './browserconfig.xml'
import favIcon16 from './favicon-16x16.png'
import favIcon32 from './favicon-32x32.png'
import favIconFallback from './favicon.ico'
import safariPinned from './safari-pinned-tab.svg'
import manifest from './site.webmanifest'

export default function Head() {
  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="60x60" href={appleIcon60} />
      <link rel="apple-touch-icon" sizes="76x76" href={appleIcon76} />
      <link rel="apple-touch-icon" sizes="120x120" href={appleIcon120} />
      <link rel="apple-touch-icon" sizes="152x152" href={appleIcon152} />
      <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180} />
      <link rel="icon" type="image/png" sizes="32x32" href={favIcon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favIcon16} />
      <link rel="manifest" href={manifest} />
      <link rel="mask-icon" href={safariPinned} color="#1ac463" />
      <link rel="shortcut icon" href={favIconFallback} />
      <meta name="msapplication-TileColor" content="#1ac463" />
      <meta name="msapplication-config" content={browserconfig} />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  )
}
