import {IconButton, Snackbar, SnackbarContent} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
    boxShadow: 'none',
    border: 'none'
  },
  bar: {
    backgroundColor: 'rgba(0, 0, 0, 0.51)',
    color: theme.palette.common.white
  }
})

const MessageSnackbar = ({classes, errorMessage, clearErrorMessage}) => {
  const {t} = useTranslation()

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={errorMessage !== null}
        autoHideDuration={6000}
        onClose={clearErrorMessage}
      >
        <SnackbarContent
          className={classes.bar}
          aria-describedby="message-id"
          message={<span id="message-id">{t(errorMessage)}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={clearErrorMessage}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    </div>
  )
}

MessageSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.string
}

export default withStyles(styles)(MessageSnackbar)
