import moment, {Moment} from 'moment'
import React, {useState} from 'react'

import {Dates} from '../../../../common'

import {DatePicker} from './DatePicker'

interface Props extends React.ComponentProps<typeof DatePicker> {
  initialStartDate: Moment | null
  initialEndDate: Moment | null
  handleDateChange: (newDates: Dates) => void
}

export const UncontrolledDatePicker: React.FC<Props> = ({
  handleDateChange,
  initialStartDate,
  initialEndDate,
  ...props
}) => {
  const [dates, setDates] = useState<Dates>({
    startDate: initialStartDate ? moment(initialStartDate) : null,
    endDate: initialEndDate ? moment(initialEndDate) : null
  })
  const onDateChange = (newDates: Dates) => {
    setDates(newDates)
    if (handleDateChange) {
      handleDateChange(newDates)
    }
  }
  return (
    <DatePicker
      {...props}
      handleDateChange={onDateChange}
      startDate={dates.startDate}
      endDate={dates.endDate}
    />
  )
}
