import {FilterBar, SelectDropdown, Typography, UncontrolledDatePicker} from '@hconnect/uikit'
import {Checkbox, CircularProgress, FormControlLabel, Paper} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import {differenceInDays, endOfDay, endOfToday, startOfDay, subDays} from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import {withTranslation} from 'react-i18next'

// import {Toolbar} from '@material-ui/core'

const PAST_PRIVACY_LIMIT_IN_DAYS = 90

const styles = (theme) => ({
  grow: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  },
  titleBar: {},
  toolBar: {
    paddingTop: 0,
    paddingBottom: 0
  },
  datePickerCalendar: {},
  datePicker: {},
  progress: {
    color: theme.palette.common.black,
    display: 'block',
    opacity: 1,
    marginRight: '30px',
    transition: 'opacity 300ms ease'
  },
  progressHidden: {
    opacity: 0.01
  },
  toggleText: {
    color: 'white',
    marginRight: '31px'
  }
})

const sublineColor = 'rgba(255, 255, 255, 0.5)'
const activeSublineColor = '#019c47'
const sublineStyle = {fontSize: 13, color: sublineColor}
const now = Date.now()

const renderTruckItem = ({t, name, number, lastPosition, type, active}) => (
  <>
    {name}
    <Typography color="inherit" style={sublineStyle}>
      {t('navbar.trucksNumber', {
        number
      })}
    </Typography>
    <Typography color="inherit" style={sublineStyle}>
      {t('navbar.trucksType', {
        type
      })}
    </Typography>
    {lastPosition && (
      <>
        <Typography
          style={{
            ...sublineStyle,
            color: active ? activeSublineColor : sublineColor
          }}
        >
          {t('navbar.trucksPosition', {
            latitude: lastPosition.lat.toFixed(3),
            longitude: lastPosition.lng.toFixed(3)
          })}
        </Typography>
        <Typography
          style={{
            ...sublineStyle,
            color: active ? activeSublineColor : sublineColor
          }}
        >
          {active
            ? t('navbar.trucksIsActiveFlag')
            : t('navbar.trucksLastActive', {
                days: differenceInDays(now, lastPosition.timestamp)
              })}
        </Typography>
      </>
    )}
  </>
)

const renderDispatchGroupItem = ({t, id, types, countries}) => (
  <>
    {id}
    <Typography color="inherit" style={sublineStyle}>
      {t('navbar.dispatchCountries', {
        countries: countries.join(', ')
      })}
    </Typography>
    <Typography color="inherit" style={sublineStyle}>
      {t('navbar.dispatchPlantTypes', {
        types: types.join(', ')
      })}
    </Typography>
  </>
)

function formatRouteTime(date) {
  return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
}

function formatDuration(duration) {
  return Math.round(duration / 1000 / 60)
}

function formatSpeed(distance, duration) {
  const durationInHours = duration / 1000 / 60 / 60
  return Math.round(distance / durationInHours)
}

const renderRouteItem = ({t, id, from, to, duration, distance}) => (
  <>
    {id}
    <Typography color="inherit" style={sublineStyle}>
      {/* Trail: {startLat}, {startLng} - {endLat}, {endLng}  */}
      {t('navbar.routesDistanceDurationSpeed', {
        distance,
        duration: formatDuration(duration),
        speed: formatSpeed(distance, duration)
      })}
    </Typography>
    <Typography color="inherit" style={sublineStyle}>
      {t('navbar.routesFromToTime', {
        from: formatRouteTime(from),
        to: formatRouteTime(to)
      })}
    </Typography>
  </>
)

// const renderPlantItem = ({ t, id, name, city }) => (
//   <>
//     {name} [{id}]
//     <Typography
//       color="inherit"
//       style={sublineStyle}
//     >
//       {city}
//     </Typography>
//   </>
// )

function stringifyDispatchGroup({id}) {
  return id
}

function stringifyRoute({id}) {
  return id
}

// function stringifyPlant({ id }) {
//   return id
// }

function stringifyTruck({name, number}) {
  return `${name} [${number}]`
}

const Navbar = ({
  classes,
  showTraffic,
  onTrafficChange,
  selectedTruck,
  selectedTimeRange,
  selectedDispatchGroup,
  selectedRoute,
  isFetching,
  selectDispatchGroup,
  selectTruck,
  selectTimeRange,
  selectRoute,
  dispatchGroups,
  trucks,
  activeTrucks,
  routes,
  t
}) => {
  const selectedDispatchGroupItem = selectedDispatchGroup
    ? dispatchGroups.filter((group) => group.id === selectedDispatchGroup)[0]
    : null

  // const selectedPlantData = selectedPlant
  //   ? plants.filter(plant => plant.id === selectedPlant)[0]
  //   : null

  const selectedTruckItem = selectedTruck
    ? trucks.filter((truck) => truck.id === selectedTruck)[0]
    : null

  const selectedRouteItem = selectedRoute
    ? routes.filter((route) => route.id === selectedRoute)[0]
    : null

  return (
    <Paper>
      <FilterBar
        data-test-id="truckmonitor-filter-bar"
        toolbarProps={{color: 'grey', className: classes.toolBar}}
      >
        <SelectDropdown
          data-test-id="dispatch-group-button"
          label={t('navbar.dispatchGroupTitle')}
          noSelectionLabel={t('navbar.dispatchGroupAll', {
            count: dispatchGroups.length
          })}
          options={dispatchGroups}
          renderItem={(options) => renderDispatchGroupItem({t, ...options})}
          stringifyItem={stringifyDispatchGroup}
          onChange={(nextValue) => {
            const id = nextValue ? nextValue.id : null
            if (selectedTruck === id || id === null) {
              selectDispatchGroup(null)
            } else {
              selectDispatchGroup(id)
            }
          }}
          selectedItem={selectedDispatchGroupItem}
        />
        {/* &#160;&#160;&#160;&#160;&#160;
        {selectedDispatchGroup && (
          <SelectDropdown
            label={t('navbar.plantsTitle')}
            noSelectionLabel={t('navbar.plantsAll', {
              count: plants.length
            })}
            options={plants}
            renderItem={PlantItem}
            stringifyItem={stringifyPlant}
            onChange={nextValue => {
              alert(t('error.featurePlaceholder'))

              // FIXME: This waits for correct support on the backend API.

              // const plantId = nextValue ? nextValue.id : null
              // if (selectedPlant === plantId || plantId === null) {
              //   selectPlant(null)
              // } else {
              //   selectPlant(plantId)
              // }
            }}
            selectedItem={selectedPlantData}
          />
        )} */}
        &#160;&#160;&#160;&#160;&#160;
        {selectedDispatchGroup && (
          <SelectDropdown
            data-test-id="trucks-button"
            label={t('navbar.trucksTitle')}
            noSelectionLabel={t('navbar.trucksAll', {
              count: trucks.length,
              active: activeTrucks.length
            })}
            options={trucks}
            renderItem={(options) => renderTruckItem({t, ...options})}
            stringifyItem={stringifyTruck}
            onChange={(nextValue) => {
              const id = nextValue ? nextValue.id : null
              if (selectedTruck === id || id === null) {
                selectTruck(null)
              } else {
                selectTruck(id)
              }
            }}
            selectedItem={selectedTruckItem}
          />
        )}
        &#160;&#160;&#160;&#160;&#160;
        {selectedTruck && (
          <UncontrolledDatePicker
            data-test-id="date-button"
            singleSelection
            initialStartDate={selectedTimeRange && selectedTimeRange.start}
            initialEndDate={selectedTimeRange && selectedTimeRange.end}
            classes={{
              datePickerContainer: classNames(classes.datePicker)
            }}
            calendarClasses={classes.datePickerCalendar}
            dateControllerProps={{
              isOutsideRange: (day) =>
                day.isAfter(endOfToday()) ||
                day.isBefore(subDays(new Date(), PAST_PRIVACY_LIMIT_IN_DAYS))
            }}
            handleDateChange={(date) => {
              // FIXME: The passed date from the UI component is a Moment instance
              // This should really be changed to a normal JS Date instance for
              // improve the developer experience.
              const selectedDate = new Date(date.startDate)
              selectTimeRange({
                start: startOfDay(selectedDate),
                end: endOfDay(selectedDate)
              })
            }}
          />
        )}
        &#160;&#160;&#160;&#160;&#160;
        {selectedTruck && (
          <SelectDropdown
            data-test-id="routes-button"
            label={t('navbar.routesTitle')}
            noSelectionLabel={t('navbar.routesAll', {
              count: routes.length
            })}
            options={routes}
            renderItem={(options) => renderRouteItem({t, ...options})}
            stringifyItem={stringifyRoute}
            onChange={(nextValue) => {
              const routeId = nextValue ? nextValue.id : null
              if (selectedRoute === routeId || routeId === null) {
                selectRoute(null)
              } else {
                selectRoute(routeId)
              }
            }}
            selectedItem={selectedRouteItem}
          />
        )}
        <div className={classes.grow} />
        <CircularProgress
          size={20}
          className={classNames({
            [classes.progress]: true,
            [classes.progressHidden]: !isFetching
          })}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              id="traffic-checkbox"
              checked={showTraffic}
              onChange={onTrafficChange}
              aria-label="ToggleTraffic"
            />
          }
          label={<Typography variant="body">{t('navbar.toggleTraffic')}</Typography>}
        />
      </FilterBar>
    </Paper>
  )
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  showTraffic: PropTypes.bool,
  onTrafficChange: PropTypes.func.isRequired,
  selectedTruck: PropTypes.string,
  isFetching: PropTypes.bool
}

export default withTranslation()(withStyles(styles)(Navbar))
