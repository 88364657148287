import {Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ToggleLayer, anchor} from 'react-laag'

import MapCard from '../MapCard'

import truckIcon from './truck-plain.svg'
import truckStartUrl from './truck-start.svg'
import truckUrl from './truck.svg'

const nativeWidth = 51
const nativeHeight = 68
const iconScale = 0.6

const styles = () => ({
  truck: {
    // this is required as the popup is in the same
    // positioning layer and without it the icon consumes space
    // which leads to a move by the icon size of the popup.
    position: 'absolute',

    // native image size: 51x68
    width: `${nativeWidth * iconScale}px`,
    height: `${nativeHeight * iconScale}px`,

    // horizontal center and vertical bottom
    // so that the pointer is pointing to the actual GPS coord
    transform: 'translate(-50%, -90%)',

    filter: 'drop-shadow(0 1px 1px #333)',
    cursor: 'pointer'
  },
  details: {
    marginTop: '12px',
    marginBottom: '12px',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    '& > p': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      marginTop: '0px',
      marginBottom: '8px',

      '&:last-child': {
        marginBottom: '0px'
      }
    }
  },
  rightText: {
    float: 'right',
    marginLeft: '12px'
  },
  button: {
    pointerEvents: 'auto',
    fontSize: '11px',
    padding: '4px 8px',
    '&:hover': {
      boxShadow: 'none'
    }
  }
})

const TruckMarker = ({
  classes,
  lat,
  lng,
  timestamp,
  id,
  name,
  number,
  type,
  category,
  onSelect,
  onDeselect,
  isRouteStart,
  capacity,
  capacityUom
}) => {
  const {t} = useTranslation()

  return (
    <ToggleLayer
      closeOnOutsideClick
      // provide placement config
      placement={{
        anchor: anchor.TOP_CENTER,
        autoAdjust: true,
        triggerOffset: 10,
        scrollOffset: 56
      }}
      // render-prop to render our layer
      renderLayer={({layerProps, isOpen, close, arrowStyle, layerSide}) =>
        // only render on `isOpen`
        isOpen && (
          <div ref={layerProps.ref} style={layerProps.style}>
            <MapCard
              key={id}
              title={name}
              subline={t('truck_card.timestamp', {
                value: timestamp.toLocaleTimeString(undefined, {
                  hour: '2-digit',
                  minute: '2-digit'
                })
              })}
              arrowStyle={arrowStyle}
              layerSide={layerSide}
              icon={truckIcon}
              onClose={close}
            >
              <div className={classes.details}>
                <p>
                  #{number}{' '}
                  {capacity && (
                    <span className={classes.rightText}>
                      {capacity} {capacityUom || 'm³'}
                    </span>
                  )}
                </p>
                <p>{t('truck_card.trucksType', {type})}</p>
                <p>{t('truck_card.trucksCategory', {category})}</p>
              </div>

              <Button
                color="secondary"
                className={classes.button}
                onClick={
                  onDeselect
                    ? () => {
                        close()
                        onDeselect()
                      }
                    : () => {
                        close()
                        onSelect()
                      }
                }
              >
                {onDeselect ? t('truck_card.button_deselect') : t('truck_card.button_select')}
              </Button>
            </MapCard>
          </div>
        )
      }
    >
      {({toggle, triggerRef}) => (
        <img
          className={classes.truck}
          lat={lat}
          lng={lng}
          role="button"
          alt={number}
          title={number}
          src={isRouteStart ? truckStartUrl : truckUrl}
          // only the `triggerRef` is required...
          ref={triggerRef}
          // ...the rest is up to you
          onClick={toggle}
        />
      )}
    </ToggleLayer>
  )
}

TruckMarker.propTypes = {
  classes: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  id: PropTypes.string
}

export default withStyles(styles)(TruckMarker)
