import React from 'react'
import {SizeMe} from 'react-sizeme'

export default function MapWrapper({children}) {
  return (
    <div style={{flex: 1, position: 'relative'}}>
      <SizeMe monitorHeight>{({size}) => React.cloneElement(children, {size})}</SizeMe>
    </div>
  )
}
