import {withStyles} from '@material-ui/core/styles'
import GoogleMap, {fitBounds} from 'google-map-react'
import {first, last} from 'lodash'
import PropTypes from 'prop-types'
import React, {useEffect, useMemo, useState} from 'react'
import {withTranslation} from 'react-i18next'

import {ClusterMarker, PlantMarker, SignalMarker, TruckMarker} from './MapMarker'
import {addMotionLine, createClusters, getMinMax} from './MapUtil'

const MAP_PADDING = 0.02

// Default centering over HC Headquarters
const DEFAULT_ZOOM = 1
const DEFAULT_CENTER = {lat: 49.4134, lng: 8.6775}

const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden'
  }
})

function Map(props) {
  const {
    classes,
    plants,
    routes,
    showTraffic,
    selectedTruck,
    selectedRoute,
    i18n,
    selectTruck,
    activeTrucks,
    trucks,
    fetchDispatchGroups,
    setPolling,
    size
  } = props

  const [mapInstance, setMapInstance] = useState(null)
  const [mapLayout, setMapLayout] = useState(null)

  // Start auto polling on boot and disable on destroy
  useEffect(() => {
    fetchDispatchGroups()
    setPolling(true)

    return () => {
      setPolling(false)
    }
  }, [fetchDispatchGroups, setPolling])

  // Use either the currently selected route or all routes for rendering on the map
  const routeItem = useMemo(() => {
    if (selectedRoute) {
      return routes.filter(({id}) => id === selectedRoute)[0]
    }

    const allSignals = routes.reduce((prev, current) => {
      prev.push(...current.signals)
      return prev
    }, [])

    if (allSignals.length > 0) {
      return {
        signals: allSignals
      }
    }
  }, [routes, selectedRoute])

  useEffect(() => {
    if (selectedTruck && routeItem) {
      const line = addMotionLine(mapInstance, routeItem.signals)
      if (line) {
        return () => {
          line.setMap(null)
        }
      }
    }
  }, [selectedTruck, routeItem, mapInstance])

  const layerTypes = []
  showTraffic && layerTypes.push('TrafficLayer')

  const plantMarkers = plants.map(({id, lat, lng, ...data}) => (
    <PlantMarker key={`plant-${id}`} lat={lat} lng={lng} {...data} />
  ))

  const truckMarkers = []

  if (selectedTruck) {
    const selectedTruckItem = trucks.filter(({id}) => id === selectedTruck)[0]
    const {id, lastPosition, ...data} = selectedTruckItem || {}

    let renderTruckPosition = lastPosition

    if (routeItem) {
      const lastRouteSignal = last(routeItem.signals)
      renderTruckPosition = lastRouteSignal
    }

    if (renderTruckPosition) {
      truckMarkers.push(
        <TruckMarker
          key={`truck-${id}-selected`}
          lat={renderTruckPosition.lat}
          lng={renderTruckPosition.lng}
          timestamp={renderTruckPosition.timestamp}
          isRouteEnd={!!routeItem}
          onSelect={null}
          onDeselect={() => selectTruck(null)}
          {...data}
        />
      )
    }

    // Render route signal dots only when an actual route is selected.
    // This simply does not make sense in cases when no route is selected
    // as wildly different times are sitting nearby or even on top of each other.
    if (routeItem && selectedRoute) {
      routeItem.signals.forEach((signal) => {
        truckMarkers.push(
          <SignalMarker
            key={`signal-${id}-${signal.lat}-${signal.lng}`}
            lat={signal.lat}
            lng={signal.lng}
            timestamp={signal.timestamp}
            {...data}
          />
        )
      })
    }

    if (routeItem) {
      const renderFirstTruckPosition = first(routeItem.signals)
      truckMarkers.push(
        <TruckMarker
          key={`truck-${id}-start`}
          lat={renderFirstTruckPosition.lat}
          lng={renderFirstTruckPosition.lng}
          timestamp={renderFirstTruckPosition.timestamp}
          isRouteStart={!!routeItem}
          onSelect={null}
          onDeselect={() => selectTruck(null)}
          {...data}
        />
      )
    }
  } else if (activeTrucks && mapLayout) {
    const truckClusters = createClusters(activeTrucks, mapLayout)

    truckClusters.forEach((cluster) => {
      if (cluster.numPoints === 1) {
        const truckItem = cluster.trucks[0]
        const {id, lastPosition} = truckItem
        truckMarkers.push(
          <TruckMarker
            key={`truck-${id}`}
            lat={lastPosition.lat}
            lng={lastPosition.lng}
            timestamp={lastPosition.timestamp}
            onSelect={() => selectTruck(id)}
            onDeselect={null}
            {...truckItem}
          />
        )
      } else {
        truckMarkers.push(
          <ClusterMarker
            key={`cluster-${cluster.lat}-${cluster.lng}`}
            lat={cluster.lat}
            lng={cluster.lng}
            truckCount={cluster.numPoints}
          />
        )
      }
    })
  }

  const autoCenterZoom = useMemo(() => {
    const relevantLocations = []

    // Focus on Route when route is selected
    if (routeItem) {
      relevantLocations.push(...routeItem.signals)
    } else if (plants) {
      // Focus on Plants by default
      relevantLocations.push(...plants)

      if (activeTrucks) {
        const activeTruckPositions = activeTrucks.map((truck) => truck.lastPosition)
        relevantLocations.push(...activeTruckPositions)
      }
    }

    if (relevantLocations.length === 0) {
      return {
        zoom: DEFAULT_ZOOM,
        center: DEFAULT_CENTER
      }
    }

    const minMax = getMinMax(relevantLocations, {
      latPad: MAP_PADDING,
      lngPad: MAP_PADDING
    })

    const centerZoom = fitBounds(minMax, size)

    // console.log(
    //   'Auto Bounds:',
    //   minMax.sw.lat.toFixed(3),
    //   minMax.sw.lng.toFixed(3),
    //   '-',
    //   minMax.ne.lat.toFixed(3),
    //   minMax.ne.lng.toFixed(3),
    //   '=>',
    //   centerZoom.center.lat.toFixed(3),
    //   centerZoom.center.lng.toFixed(3),
    //   '@',
    //   centerZoom.zoom,
    //   'from',
    //   relevantLocations.length,
    //   'positions'
    // )

    return centerZoom
  }, [plants, routeItem, activeTrucks, size])

  return (
    <div className={classes.container}>
      <GoogleMap
        options={(maps) => ({
          panControl: false,
          mapTypeControl: true,
          scrollwheel: true,
          fullscreenControl: false,
          mapTypeControlOptions: {
            position: maps.ControlPosition.LEFT_BOTTOM,
            style: maps.MapTypeControlStyle.DEFAULT,
            mapTypeIds: ['roadmap', 'hybrid']
          }
        })}
        zoom={autoCenterZoom.zoom}
        center={autoCenterZoom.center}
        // This can't be change which is tricky to ensure in React-land
        // when being based on dynamic data.
        // defaultZoom={defaultFit.zoom}
        // defaultCenter={defaultFit.center}

        // Instead we use some sensible default right now which show
        // the central european map by default.
        defaultZoom={DEFAULT_ZOOM}
        defaultCenter={DEFAULT_CENTER}
        // Note: This flag is required to be able to render the MotionLine
        // for selected trucks.
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(instance) => setMapInstance(instance)}
        onChange={(layout) => setMapLayout(layout)}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          libraries: 'geometry',
          language: i18n.language
        }}
        layerTypes={layerTypes}
      >
        {plantMarkers}
        {truckMarkers}
      </GoogleMap>
    </div>
  )
}

Map.propTypes = {
  classes: PropTypes.object.isRequired,
  showTraffic: PropTypes.bool,
  center: PropTypes.object,
  zoom: PropTypes.number,
  plants: PropTypes.array,
  trucks: PropTypes.array,
  positions: PropTypes.array,
  selectedTruck: PropTypes.string,
  selectTruck: PropTypes.func
}

export default withTranslation()(withStyles(styles)(Map))
