import {connect} from 'react-redux'

import Map from '../components/Map'
import {
  fetchDispatchGroups,
  resetPositions,
  selectRoute,
  selectTruck,
  setPolling
} from '../modules/Map.action'

const MapContainer = connect(
  (state) => ({
    trucks: state.map.trucks.items,
    activeTrucks: state.map.trucks.activeItems,
    plants: state.map.plants.items,
    routes: state.map.positions.routes,

    selectedPlant: state.map.interactive.selectedPlant,
    selectedTruck: state.map.interactive.selectedTruck,
    selectedRoute: state.map.interactive.selectedRoute
  }),
  (dispatch) => ({
    setPolling: (enabled) => dispatch(setPolling(enabled)),
    fetchDispatchGroups: (value) => dispatch(fetchDispatchGroups(value)),
    selectRoute: (id) => dispatch(selectRoute(id)),
    selectTruck: (id) => {
      dispatch(resetPositions())
      dispatch(selectTruck(id))
    }
  })
)(Map)

export default MapContainer
