import {connect} from 'react-redux'

import Navbar from '../components/Navbar'
import {
  resetPositions,
  selectDispatchGroup,
  selectPlant,
  selectRoute,
  selectTimeRange,
  selectTruck
} from '../modules/Map.action'

const NavbarContainer = connect(
  (state) => ({
    dispatchGroups: state.map.dispatchGroups.items,
    trucks: state.map.trucks.items,
    activeTrucks: state.map.trucks.activeItems,
    plants: state.map.plants.items,
    routes: state.map.positions.routes,

    selectedTimeRange: state.map.interactive.selectedTimeRange,
    selectedTruck: state.map.interactive.selectedTruck,
    selectedPlant: state.map.interactive.selectedPlant,
    selectedDispatchGroup: state.map.interactive.selectedDispatchGroup,
    selectedRoute: state.map.interactive.selectedRoute,

    isFetching:
      state.map.trucks.isFetching || state.map.plants.isFetching || state.map.positions.isFetching
  }),
  (dispatch) => ({
    selectDispatchGroup: (id) => dispatch(selectDispatchGroup(id)),
    selectTruck: (id) => {
      dispatch(resetPositions())
      dispatch(selectTruck(id))
    },
    selectPlant: (id) => dispatch(selectPlant(id)),
    selectTimeRange: (timeRange) => dispatch(selectTimeRange(timeRange)),
    selectRoute: (id) => dispatch(selectRoute(id))
  })
)(Navbar)

export default NavbarContainer
