import 'core-js/stable'
import 'regenerator-runtime/runtime'

import {AppInsightsAdapter} from '@hconnect/common/logging'
import {History, withRouter, withTheme} from '@hconnect/uikit'
import {CircularProgress} from '@material-ui/core'
import {ConnectedRouter} from 'connected-react-router'
import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {HelmetProvider} from 'react-helmet-async'

import App from './App'
import {loginFlow, withStore} from './App.store'
import Localization from './locales'
import {unregister as disableServiceWorker} from './serviceWorker'

AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

void Localization()

const routerClass = ConnectedRouter
const routerConfig = {
  history: History
}

function render(CurrentApp) {
  const Root = withTheme()(withStore(withRouter(routerClass, routerConfig)(CurrentApp)))

  ReactDOM.render(
    <Suspense fallback={<CircularProgress />}>
      <HelmetProvider>
        <Root />
      </HelmetProvider>
    </Suspense>,
    document.getElementById('root')
  )
}

async function start() {
  await loginFlow.getLoginState()
  render(App)
}

console.log('2019-02-25-10:18')

if (process.env.NODE_ENV !== 'production') {
  console.log('Enabling hot loading!')
  if (module.hot) {
    module.hot.accept('./App', () => {
      /* eslint-disable-next-line @typescript-eslint/no-var-requires */
      const NextApp = require('./App').default
      render(NextApp)
    })
  }
}

void start()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
disableServiceWorker()

// Unfortunately we are not allowed to use the GeoCode API with the current
// Google Maps API key. This would be a nice feature for showing current
// truck positions.

// async function loadPlace() {
//   const result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
//   const json = await result.json()

//   console.log("Place:",json)
// }

// loadPlace()
