import {withStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'

import Popup from './Popup'

const styles = () => ({
  title: {
    fontWeight: '400',
    fontSize: '18px',
    color: '#000',
    lineHeight: 1,
    paddingLeft: '36px',
    paddingRight: '36px',
    paddingTop: '28px'
  },
  icon: {
    opacity: 0.4,
    width: '16px',
    maxHeight: '18px',
    position: 'absolute',
    left: '14px',
    top: '30px'
  },
  subline: {
    fontWeight: '400',
    lineHeight: 1,
    fontSize: '11px',
    position: 'absolute',
    left: '36px',
    top: '14px'
  },
  content: {
    paddingLeft: '36px',
    paddingRight: '36px',
    paddingBottom: '12px',
    fontSize: '14px',
    lineHeight: 1
  },
  closeButton: {
    opacity: 0.4,
    position: 'absolute',
    right: '8px',
    top: '8px',
    width: '16px',
    height: '16px',
    color: '#000'
  }
})

const MapCard = ({children, classes, title, subline, icon, onClose, arrowStyle, layerSide}) => (
  <Popup arrowStyle={arrowStyle} layerSide={layerSide}>
    <CloseIcon className={classes.closeButton} onClick={onClose} width="10" height="10" />

    {subline && <div className={classes.subline}>{subline}</div>}
    <div className={classes.title}>{title}</div>
    {icon && <img src={icon} className={classes.icon} alt="Icon" />}

    <div className={classes.content}>{children}</div>
  </Popup>
)

MapCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subline: PropTypes.string,
  icon: PropTypes.string,
  onClose: PropTypes.func
}

export default withStyles(styles)(MapCard)
