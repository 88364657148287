import {connect} from 'react-redux'

import {clearErrorMessage} from '../App.action'
import MessageSnackbar from '../components/MessageSnackbar'

const MessageSnackbarContainer = connect(
  (state) => ({
    errorMessage: state.messages.error
  }),
  (dispatch) => ({
    clearErrorMessage: () => dispatch(clearErrorMessage())
  })
)(MessageSnackbar)

export default MessageSnackbarContainer
