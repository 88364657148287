import {LogoutIcon} from '@hconnect/uikit'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'

import {loginFlow} from './App.store'
import truckmonitorFontFaceSvg from './assets/appname.svg'
import truckmonitorIconSvg from './assets/icon.svg'
import ErrorBoundary from './components/ErrorBoundary'
import FooterContainer from './container/FooterContainer'
import MessageSnackbarContainer from './container/MessageSnackbarContainer'
import Head from './core/meta/Head'
import MainPage from './pages/Main'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },

  page: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  spacerX: {
    flexGrow: 1
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px'
  }
})

function DispatchBrandingLogos() {
  return (
    <>
      <img
        alt="Dispatch Logo"
        src={truckmonitorIconSvg}
        width="16"
        height="16"
        style={{marginRight: '8px'}}
      />
      <img
        alt="Dispatch Application"
        src={truckmonitorFontFaceSvg}
        width="76"
        height="24"
        style={{height: '24px', marginTop: '8px'}}
      />
    </>
  )
}

const App = ({classes}) => (
  <ErrorBoundary>
    <Head />
    <div className={classes.root}>
      <div className={classes.header}>
        <DispatchBrandingLogos />
        <div className={classes.spacerX}>&#160;</div>
        <LogoutIcon size="small" onClick={() => loginFlow.startLogoutProcess()} />
      </div>

      <div className={classes.page}>
        <MainPage />
      </div>

      <div className={classes.footer}>
        <FooterContainer />
      </div>
    </div>

    <MessageSnackbarContainer />
  </ErrorBoundary>
)

export default withStyles(styles)(App)
