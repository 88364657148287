import React, {Component} from 'react'

import MapWrapper from '../components/MapWrapper'
import MapContainer from '../container/MapContainer'
import NavbarContainer from '../container/NavbarContainer'

class Main extends Component {
  state = {
    showTraffic: false
  }

  handleTrafficChange = () => {
    const {showTraffic} = this.state
    this.setState({showTraffic: !showTraffic})
  }

  render() {
    const {showTraffic} = this.state

    return (
      <>
        <NavbarContainer onTrafficChange={this.handleTrafficChange} showTraffic={showTraffic} />
        <MapWrapper>
          <MapContainer showTraffic={showTraffic} />
        </MapWrapper>
      </>
    )
  }
}

export default Main
